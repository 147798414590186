// i18next-extract-mark-ns-start buy-now-pay-later-for-prestashop

//Landing redirected to /payment-methods to hide from BdE license - Discussed with David and Alex on 2024-08-08

//Payment methods
import {AnchorLink} from 'components/AnchorLink';
import {Background} from 'components/Background';
import {ContactSalesButton} from 'components/ContactSalesButton';
import {Content} from 'components/Content';
import {Section, SectionActions, SectionHeader, SectionImage} from 'components/Section';
import {SEO} from 'components/SEO';
import {SignUpButton} from 'components/SignUpButton';
import {graphql} from 'gatsby';
import {Trans, useI18next} from 'gatsby-plugin-react-i18next';

//Partners
import index2 from 'images/best_payment_gateway_rates.svg';

//Images
import prestashop_cofidis from 'images/prestashop_cofidis.svg';

// Clients
import index1 from 'images/payment_gateway.svg';
import index3 from 'images/shopify_payment_gateway.svg';

import React from 'react';
import styled from 'styled-components';
import {List, OrderedList} from 'components/List';
import {IndexBackground} from 'components/landings/IndexBackground';
import {MainTitleContent} from 'components/combo-pages/MainTitleContent';
import {MainTitleImg} from 'components/combo-pages/MainTitleImg';

import {
  ApplePayForPrestashopLink,
  BizumForPrestashopLink,
  CofidisPageLink,
  GooglePayForPrestashopLink,
  InternalPageLink,
  PaypalForPrestashopLink
} from 'components/links/Pages';
import {SignUpLink} from 'components/links/Other';
import {SetupIntegration} from 'components/combo-pages/SetupIntegration';
import {BlogLink} from 'components/links/Blog';

const LargeSectionHeader = styled(SectionHeader)`
  h1 {
    font-size: 2.625rem;
    line-height: 3.25rem;

    @media (max-width: 768px) {
      font-size: 2rem;
      line-height: 2.75rem;
    }
  }
`;

const BnplForPrestashop: React.FC = () => {
  const {t} = useI18next();
  return (
    <>
      <SEO
        path="buy-now-pay-later-for-prestashop"
        title={t('Prestashop Buy Now Pay Later (BNPL)')}
        description={t(
          'Add PrestaShop Buy Now, Pay Later to your store to let customers pay in installments while you get paid in full. Boost satisfaction and sales. Start now ››'
        )}
      />
      <IndexBackground>
        <Content>
          <Section reverseOnMobile>
            <MainTitleContent sx={{maxWidth: {all: '585px', md: '100%'}}}>
              <LargeSectionHeader underline tagName="h1">
                <Trans>Accept buy now, pay later on PrestaShop</Trans>
              </LargeSectionHeader>
              <Trans parent="p">
                Improve the payment experience, increase conversion rates, and boost customer
                retention by accepting buy now, pay later (BNPL) instalment payments in your
                PrestaShop store.
              </Trans>
              <Trans parent="p">
                Use a PrestaShop payment gateway that lets you accept the widest range of online
                payment methods including Cofidis financing. Configure Cofidis 4xcard or Cofidis Pay
                to let your customers pay in 4 installments or on a flexible schedule, while you
                always get paid in full.
              </Trans>
              <SectionActions>
                <SignUpButton variant="light" style={{marginRight: 15}}>
                  <Trans>Open an Account</Trans>
                </SignUpButton>
                <ContactSalesButton />
              </SectionActions>
            </MainTitleContent>
            <MainTitleImg src={prestashop_cofidis} alt="MONEI cofidis" title="MONEI cofidis" />
          </Section>
        </Content>
      </IndexBackground>
      <Background>
        <Content>
          <Section>
            <SectionImage
              src={index2}
              alt="MONEI Payment Gateway"
              title="MONEI Payment Gateway"
              width={600}
              height={350}
              mobileWidth={280}
            />
            <div>
              <SectionHeader sx={{maxWidth: {all: '491px'}}}>
                <Trans>Boost your online sales with buy now, pay later for PrestaShop</Trans>
              </SectionHeader>
              <Trans parent="p">
                <InternalPageLink slug="payment-methods/cofidis">Cofidis</InternalPageLink> is
                Spain’s leader in consumer credit. Easily configure the PrestaShop buy now, pay
                later payment method to give shoppers the option to finance their purchases:
              </Trans>
              <List>
                <Trans parent="li">
                  <InternalPageLink slug="cofidis">Cofidis 4xCard</InternalPageLink> lets shoppers
                  pay in 4 installments over a 90 day period
                </Trans>
                <Trans parent="li">
                  <InternalPageLink slug="cofidis-pay">Cofidis Pay 6x12x24</InternalPageLink> lets
                  shoppers pay in up to 50 months
                </Trans>
              </List>
              <Trans parent="p">
                You’ll receive the full amount one day after the order is placed and with Cofidis
                and MONEI, you’ll never have to chase customers for subsequent payments.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark">
                  <Trans>Accept BNPL Now</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
          </Section>
          <Section reverseOnMobile>
            <div>
              <SectionHeader sx={{maxWidth: {all: '499px'}}}>
                <Trans>Buy now, pay later with Cofidis: how it works</Trans>
              </SectionHeader>
              <Trans parent="p">
                After you create your MONEI account and{' '}
                <AnchorLink href="https://support.monei.com/hc/articles/4402319128465">
                  configure the Cofidis payment method
                </AnchorLink>
                , your customers can choose their preferred buy now, pay later option.
              </Trans>
              <Trans parent="p">
                For consumers, the <CofidisPageLink>payment process is simple</CofidisPageLink>.
                Once they provide their personal ID and credit card details, they can complete their
                online application in less than three minutes.
              </Trans>
              <Trans parent="p">
                The first transaction takes place at the time of purchase, and the remaining
                payments happen automatically 30, 60, and 90 days after the order was placed for
                Cofidis 4xCard. And up to 50 months with Cofidis Pay 6x12x24x. You’ll get paid in
                full one day after the first transaction.
              </Trans>
              <Trans parent="p">
                Get{' '}
                <AnchorLink href="https://support.monei.com/hc/sections/360006008278">
                  more information
                </AnchorLink>{' '}
                about enabling buy now, pay later with Cofidis, and when you’ll get paid.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark">
                  <Trans>Discover MONEI</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
            <SectionImage
              src={index1}
              alt="Secure payment gateway"
              title="Secure payment gateway"
              width={500}
              height={500}
              mobileWidth={280}
            />
          </Section>
          <Section>
            <SectionImage
              src={index3}
              alt="Secure payment gateway"
              title="Secure payment gateway"
              width={400}
              height={428}
              mobileWidth={180}
            />
            <div>
              <SectionHeader sx={{maxWidth: {all: '515px', sm: '100%'}}}>
                <Trans>Why use MONEI’s payment gateway?</Trans>
              </SectionHeader>
              <Trans parent="p">
                Improving the checkout experience, reaching more people, and increasing sales are
                all vital to the success of your e-commerce business. That’s why it’s important to
                accept all the most popular{' '}
                <InternalPageLink slug="payment-methods">payment methods</InternalPageLink>.
              </Trans>
              <Trans parent="p">
                Aside from <BlogLink slug="buy-now-pay-later">buy now, pay later</BlogLink> options
                like Cofidis, shoppers look for many{' '}
                <BlogLink slug="alternative-payment-methods">online payment alternatives</BlogLink>.
                To stay competitive, you need to keep up and accept{' '}
                <BlogLink slug="digital-wallet-ecommerce">digital wallets</BlogLink> like{' '}
                <PaypalForPrestashopLink>PayPal</PaypalForPrestashopLink>,{' '}
                <GooglePayForPrestashopLink>Google Pay</GooglePayForPrestashopLink>,{' '}
                <InternalPageLink slug="payment-methods/click-to-pay">
                  Click to Pay
                </InternalPageLink>
                , and <ApplePayForPrestashopLink>Apple Pay</ApplePayForPrestashopLink> as well as
                more <BlogLink slug="local-payment-methods">local payment methods</BlogLink>{' '}
                including:
              </Trans>
              <List>
                <Trans parent="li">
                  <BizumForPrestashopLink>Bizum</BizumForPrestashopLink> peer-to-peer (P2P) payments
                  and <InternalPageLink slug="payment-methods/cofidis">Cofidis</InternalPageLink>{' '}
                  Buy Now, Pay Later (Spain)
                </Trans>
                <li>
                  <Trans>
                    <InternalPageLink slug="payment-methods/multibanco">
                      Multibanco
                    </InternalPageLink>{' '}
                    bank transfer (Portugal)
                  </Trans>
                </li>
                <li>
                  <Trans>
                    <InternalPageLink slug="payment-methods/giropay">Giropay</InternalPageLink> bank
                    transfer (Germany, Austria, and Switzerland)
                  </Trans>
                </li>
                <Trans parent="li">
                  <InternalPageLink slug="payment-methods/bancontact">Bancontact</InternalPageLink>{' '}
                  bank transfer (Belgium)
                </Trans>
                <Trans parent="li">
                  <InternalPageLink slug="payment-methods/trustly">Trustly</InternalPageLink> bank
                  transfer (Baltic and Nordic regions, and the UK)
                </Trans>
              </List>
              <Trans parent="p">
                Manage your entire payment stack (online and offline) from a single platform. As you
                sell more, your transaction fees will decrease in real-time so you can save money to
                reinvest in your PrestaShop store. Choose a payment gateway that grows with your
                business.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark">
                  <Trans>Choose MONEI</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
          </Section>
        </Content>
      </Background>
      <SetupIntegration>
        <SectionHeader underline tagName="h3">
          <Trans>Add PrestaShop buy now, pay later to your website</Trans>
        </SectionHeader>
        <Trans parent="p">
          Easily integrate your PrestaShop store with our payment gateway to start accepting buy
          now, pay later (and more payment methods) today:
        </Trans>
        <OrderedList>
          <Trans parent="li">
            <SignUpLink>Sign up for MONEI</SignUpLink>
          </Trans>
          <Trans parent="li">
            <AnchorLink href="https://support.monei.com/hc/articles/4402319128465">
              Configure BNPL Installment Payments by Cofidis
            </AnchorLink>{' '}
            in your MONEI dashboard
          </Trans>
          <Trans parent="li">
            Follow the{' '}
            <AnchorLink href="https://docs.monei.com/docs/e-commerce/prestashop">
              PrestaShop integration guide
            </AnchorLink>{' '}
            to connect with MONEI
          </Trans>
          <Trans parent="li">
            Learn how to{' '}
            <AnchorLink href="https://support.monei.com/hc/categories/360003378957">
              configure all payment methods
            </AnchorLink>
          </Trans>
        </OrderedList>
      </SetupIntegration>
    </>
  );
};

export default BnplForPrestashop;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {ns: {in: ["common", "buy-now-pay-later-for-prestashop"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
